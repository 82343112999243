import React from "react";
import Navbar from "../components/Navbar";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSelector } from "react-redux";

const ProfilePage = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className="bg-preflop_mage-purple h-screen">
      <Navbar />

      <a
        href={`https://billing.stripe.com/p/login/test_eVaeVtdCn2nZ8fKbII?prefilled_email=${user.email}`}
      >
        {" "}
        edit your fucking subscription
      </a>
    </div>
  );
};

export default ProfilePage;

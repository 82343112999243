import React from "react";
import Navbar from "./Navbar";
import logo from "../images/logo/LogoLightGradient.png";
import { useNavigate } from "react-router-dom";
import svg from "../images/Cloudy.svg";
import "../css/devider.css";
import "animate.css";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import { FaArrowDown } from "react-icons/fa";
const Header = () => {
  const navigate = useNavigate();

  return (
    <header id="main">
      <Navbar />

      <div className="text-white items-center text-center flex flex-col justify-center h-[90vh] z-20 relative ">
        {/* img */}
        <img
          src={logo}
          alt="logo"
          className=" z-50 w-[220px] h-[200px] md:w-[320px]  md:h-[300px] lg:w-[420px]  lg:h-[400px] "
        />
        <h1 className=" z-50 text-xl sm:text-3xl  md:text-5xl m-2 space-x-10  tracking-[3px] animate__animated animate__fadeInDown animate__delay-1s">
          Best Preflop Solution Available
        </h1>
        <h4 className="z-50 text-sm  md:text-3xl m-2 space-x-10  tracking-[1px] animate__animated animate__fadeInDown animate__delay-2s">
          Study, Learn and crush your competition
        </h4>
        <button
          className="z-50 btn m-2 p-4 space-x-10 text-sm  md:text-lg tracking-[2px] rounded-3xl extra animate__animated animate__fadeInDown animate__delay-2s hover:shadow-sm hover:shadow-preflop_mage-purple"
          onClick={() => navigate("/preflopmage")}
        >
          Start free Trial
        </button>
        {/* <div
          className="w-[100%] h-[40vh] absolute bottom-0 z-0"
          style={{ backgroundImage: `url(${svg})` }}
        /> */}
        <a href="#about" className="absolute bottom-16">
          <FaArrowDown className="h-8  md:h-12 w-[3rem] animate-float" />
        </a>
        {/* <div className="shapedividers_com-2957 bottom-0 h-[300px] w-full"></div> */}
        {/* <div className="shapedividers_com-33 bottom-0 h-[300px] w-full"></div> */}
      </div>
    </header>
  );
};

export default Header;

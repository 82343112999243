import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo/Artboard 3 copy.png";
import "../css/footer.css";
const Footer = () => {
  return (
    <div className="relative  w-full bg-[#3b296a]  overflow-visible">
      <div className="custom-shape-divider-top-1676547837">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>

      <div className="pt-[12rem] p-8 w-full h-max text-white text-center uppercase bg-[#5b4293] z-50 flex  flex-col md:flex-row justify-center gap-5 md:gap-10 lg:gap-20 m-auto items-center ">
        <div className="flex flex-col md:flex-row gap-5  md:gap-10 lg:gap-20 ">
          <Link to="/pricing" className="hover:translate-y-1 transition-all">
            Pricing
          </Link>
          <Link
            to="/"
            className="hover:translate-y-1 transition-all relative line-through"
          >
            MTT
            <div className="absolute text-xs left-4 top-4 -rotate-[20deg] font-bold">
              Soon
            </div>
          </Link>
        </div>
        <div className="flex justify-center hover:translate-y-1 transition-all">
          <a href="#main">
            <img src={logo} className="w-24 bg-white rounded-2xl p-3" />
          </a>
        </div>
        <div className="flex flex-col md:flex-row gap-5  md:gap-10 lg:gap-20 ">
          <Link to="/faq" className="hover:translate-y-1 transition-all">
            FAQ
          </Link>
          <Link to="/contact" className="hover:translate-y-1 transition-all">
            Contact
          </Link>
        </div>
      </div>
      <div className="w-[80%] text-center text-white m-auto p-5 rounded-t-2xl flex  justify-center items-center flex-col text-base md:text-xl z-50">
        <h6 className="z-50">
          © 2022 <span className="text-preflop_mage-gold">Preflop Mage </span>
          All rights Reserved
        </h6>
        {/* <a href="#" id="open_preferences_center">
          Update cookies preferences
        </a> */}
      </div>
    </div>
  );
};

export default Footer;

export const LOGIN = " LOGIN";
export const LOGOUT = " LOGOUT";

const initialState = {
  user: null,
};

const UserReducer = (state = initialState, action) => {
  if (action.type === LOGIN) {
    return {
      ...state,
      user: action.payload,
    };
  }
  if (action.type === LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return { ...state };
};
export default UserReducer;

import { CheckBadgeIcon, NoSymbolIcon } from "@heroicons/react/24/solid";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import db from "../firebase";
import toast, { Toaster } from "react-hot-toast";

const ActivatePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  let [searchParams] = useSearchParams();
  const { state } = useLocation();
  const user = useSelector((state) => state.user);

  const notifyPositive = () =>
    toast(
      <div className="flex flex-row  items-center">
        <CheckBadgeIcon className="w-[15%] text-preflop_mage-gold" />
        Your Account has been succesfully bounded!
      </div>
    );
  const notifyInfo = () =>
    toast(
      <div className="flex flex-row  items-center">
        <CheckBadgeIcon className="w-[15%] text-preflop_mage-gold" />
        Your Account is bounded already!
      </div>
    );
  const notifyNegative = () =>
    toast(
      <div className="flex flex-row  items-center">
        <NoSymbolIcon className="w-[15%] text-red-700" />
        Something went wrong
      </div>
    );

  useEffect(() => {
    addConfirmationDataToDatabase(state, user).then(() => setIsLoading(false));
  }, []);

  const addConfirmationDataToDatabase = async (params, user) => {
    setIsLoading(true);

    let hardwareInfo = await getDoc(doc(db, `customers/${user.uid}`));
    if (hardwareInfo?.data()?.hardware) {
      setIsLoading(false);
      notifyInfo();
      return;
    }
    try {
      await setDoc(
        doc(db, `customers/${user.uid}`),
        {
          hardware:
            searchParams?.get("code")?.length > 10
              ? searchParams.get("code")
              : params.id,
          activationTime: new Date(),
        },
        { merge: true }
      );
      setIsLoading(false);
      notifyPositive();
    } catch (error) {
      setIsLoading(false);
      notifyNegative();
    }
  };
  return (
    <div className="h-screen w-screen bg-preflop_mage-purple">
      <Navbar />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col justify-center items-center h-[90%]">
          {/* <div className="bg-white flex flex-col justify-center items-center rounded-3xl p-10"> */}
          <div className="lg:text-4xl max-w-3xl text-center text-xl font-bold text-white">
            if Something went wrong contact us on{" "}
            <a
              href="https://discord.gg/Cmhst8xt7j"
              className="underline text-preflop_mage-gold "
            >
              Discord
            </a>{" "}
            or send us an email at: preflopmageteam@preflopmage.com
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default ActivatePage;

import React, { useEffect, useState } from "react";
import { FaQuoteRight } from "react-icons/fa";
import image from "../images/Katri.jpg";
import lukas from "../images/lukas.png";
import kamil from "../images/Takhle_vypada_Kamil.jfif";
import "../css/Reviews.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
let reviews = [
  {
    image: image,
    name: "Katrisone",
    title: "Highstakes Pro",
    text: "We have been working with the Preflop Mage team for a long time. I know how they work and how much energy they put into software development. Thankfully, thanks to this effort, we no longer have to speculate on what the best preflop strategies are, because thanks to Mage we get them on a golden platter, even in simple and easy to apply variants for complete beginners.",
  },
  {
    image: lukas,
    name: "Lukas06v",
    title: "500nl+ Regular",
    text: "Preflop Mage is the software I wish I had available when I was grinding my way up towards midstakes and finding more knowledge on the preflop game. There is no other software on the market where you can study preflop in such an efficient and compact way.",
  },
  {
    image: kamil,
    name: "Kamil",
    title: "Poker enthusiast",
    text: "Finally, I have a tool to comfortably study the preflop game with quick access to all spots. Thanks to its advanced features, Preflop Mage also serves as a useful extension to traditional postflop solvers.",
  },
];
const ReviewsSection = () => {
  const [people, setPeople] = useState(reviews);
  const [index, setIndex] = React.useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 8000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className="section pt-12 bg-preflop_mage-purple font-Roboto relative h-max  ">
      <div className="title ">
        <h2 className="text-5xl text-preflop_mage-gold">Reviews</h2>
      </div>
      <div className="section-center z-50">
        {people.map((person, personIndex) => {
          const { id, image, name, text, title } = person;

          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = "lastSlide";
          }

          return (
            <article className={position} key={id}>
              <div className="flex justify-center items-center relative m-auto">
                <div className="overflow-hidden h-[150px]  w-[150px] bg-preflop_mage-dark_purple rounded-full">
                  <img src={image} alt={name} className="person-img" />
                  <FaQuoteRight className="hidden md:block icon text-preflop_mage-gold absolute -top-4 right-[38%]" />
                </div>
              </div>
              <p className="text-preflop_mage-gold text">{title}</p>
              <p className="title text-white max-w-lg m-auto self-center">
                {text}
              </p>
              <h4 className="text-preflop_mage-gold font-bold text-2xl ">
                {name}
              </h4>
            </article>
          );
        })}
        <button
          className="prev bg-preflop_mage-gold hidden md:block hover:bg-preflop_mage-gold_light"
          onClick={() => setIndex(index - 1)}
        >
          <FiChevronLeft />
        </button>
        <button
          className="next bg-preflop_mage-gold hidden md:block hover:bg-preflop_mage-gold_light"
          onClick={() => setIndex(index + 1)}
        >
          <FiChevronRight className="ml-2" />
        </button>
      </div>
    </section>
  );
};

export default ReviewsSection;

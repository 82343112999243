import Footer from "./components/Footer";

import MainPage from "./pages/MainPage";

import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PricingPage from "./pages/PricingPage";
import LoginPage from "./pages/LoginPage";

import ProtectedRoute from "./pages/ProtectedRoute";
import PreflopMagePage from "./pages/PreflopMagePage";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "./firebase";
import { LOGIN, LOGOUT } from "./reducer/userReducer";
import ActivatePage from "./pages/ActivatePage";
import SubsDataPage from "./pages/SubsDataPage";
import ProfilePage from "./pages/ProfilePage";
import ErrorPage from "./pages/ErrorPage";
import ContactPage from "./pages/ContactPage";
import FaqPage from "./pages/FaqPage";
// import Error from "./pages/Error";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  return (
    <>
      {/* <Cookie /> */}
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />

          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <PricingPage />
              </ProtectedRoute>
            }
          />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FaqPage />} />

          {/* <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/activate"
            element={
              <ProtectedRoute>
                <ActivatePage />
              </ProtectedRoute>
            }
          />
          <Route path="/preflopMage" element={<PreflopMagePage />} />

          {/* <Route path="/subsdatapage" element={<SubsDataPage />} /> */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;

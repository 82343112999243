import React from "react";
import Navbar from "../components/Navbar";
import mage from "../images/magePhotos/mageBg.png";
import rangeAsymetry from "../images/magePhotos/rangeAsymetry.png";
import { BsWindows } from "react-icons/bs";
import DisclosureBox from "../components/DisclosureBox";
import { SiDiscord } from "react-icons/si";
const PreflopMagePage = () => {
  return (
    <div className="md:h-screen h-max lg:w-[100%] bg-preflop_mage-purple">
      <Navbar />

      <div className=" flex flex-col md:flex-row max-w-screen-xl mx-auto">
        {/* <div className="    text-white font-bold  ">
          <div className="relative ">
            <img
              src={mage}
              className="lg:w-[850px] border-[10px] rounded-2xl border-[#252525] shadow-lg shadow-black "
            />
            <img
              src={rangeAsymetry}
              className=" absolute w-40 right-5 top-[50%]  lg:-right-[5%] lg:top-[40%] lg:w-[500px] border-[4px] md:w-[300px]  lg:border-[10px] rounded-2xl border-[#252525] animate-float shadow-lg shadow-black"
            />
          </div>
        </div> */}
        <div className="w-full flex flex-col justify-center items-center p-16 font-Robot">
          <div className="flex flex-col  justify-center items-center  text-white ">
            <div className="lg:text-5xl  text-4xl text-center p-10 font-bold">
              <span className="text-preflop_mage-gold"> Your </span>
              Preflop Journey starts right here !
            </div>
            <h3 className="text-2xl">Latest release</h3>
            <div className="flex flex-row">
              <BsWindows className="m-1" />
              Windows
            </div>
            <a
              href="/preflopmage.rar"
              className="btn-gold rounded-3xl text-3xl p-5 m-5 hover:shadow-md hover:shadow-black"
              download
            >
              Download
            </a>
          </div>
          {/* <div className="flex flex-col  justify-center items-center mt-[2rem] text-white ">
            {" "}
            <h3 className="text-2xl">Legacy Version</h3>
            <div className="flex flex-row">
              <BsWindows className="m-1" />
              Windows
            </div>
            <a
              href="/preflopmage.rar"
              className="btn-gold rounded-3xl text-3xl p-5 m-5 hover:shadow-md hover:shadow-black"
              download
            >
              Download
            </a>
          </div>
          <div className="flex flex-col  justify-center items-center mt-[2rem] text-white ">
            <h3 className="text-2xl">Join our discord server</h3>
            <a
              href="https://discord.gg/Cmhst8xt7j"
              className="underline text-preflop_mage-gold flex gap-4 items-center"
            >
              <SiDiscord className="w-16 h-16 text-white bg-[#5865f2] p-2 rounded-2xl m-10" />{" "}
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PreflopMagePage;

import React from "react";
import { Link } from "react-router-dom";
import photo from "../images/magePhotos/laptopWbg.png";
import photo2 from "../images/magePhotos/mageBg2.png";
import svg from "../images/pattern.svg";
import { motion } from "framer-motion";
import "animate.css";

// import photo2 from "../images/magePhotos/detail.png";
const Section = () => {
  return (
    <section className="h-screen bg-preflop_mage-purple" id="about">
      <div className="h-full w-full relative  items-center grid  lg:grid-cols-2 lg:grid-flow-col p-5  max-w-screen mx-auto">
        {/* <div className=" w-[35%] absolute top-[24%] bg-preflop_mage-gold  h-[450px] skew-y-[16deg]  z-0 overflow-hidden rounded-2xl" /> */}

        <motion.div
          initial={{ x: window.innerWidth > 400 ? -350 : -150, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="relative  flex justify-center items-center "
        >
          <img
            src={photo}
            className="sm:w-[550px] lg:w-[90ch]   "
            loading="lazy"
            alt="software preview"
          />
          {/* <div className="absolute m-auto top-10 right-10 p-2 border-black border-[2rem] rounded-2xl"> */}
        </motion.div>
        <div className="text-4xl font-bold h-full flex  justify-center lg:items-center text-white">
          <motion.div
            initial={{ x: window.innerWidth > 400 ? 350 : 150, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className=" justify-start flex flex-col  xl:text-4xl font-bold text-2xl  w-[85%]"
          >
            <ul className="list-disc">
              <li className="showDetails">
                <p className="lg:mb-10 mb-5">
                  <span className="xl:text-[45px]  text-preflop_mage-gold">
                    Optimal
                  </span>{" "}
                  preflop has never been this accessible!
                </p>
              </li>
              <li className="showDetails">
                <p className="lg:mb-10 mb-5">
                  <span className="xl:text-[45px]  text-preflop_mage-gold">
                    Full
                  </span>{" "}
                  preflop strategy in simple and compact charts
                </p>
              </li>
              <li>
                <p className="lg:mb-10 mb-5">
                  <span className="xl:text-[45px]   text-preflop_mage-gold">
                    Fastest
                  </span>{" "}
                  tool on the market to look up any range!
                </p>
              </li>

              <Link to={"/preflopMage"}>
                <button className="bg-preflop_mage-gold p-4 xl:p-6 hover:bg-preflop_mage-gold_light text-base xl:text-3xl rounded-3xl hover:text-black  hover:shadow-md hover:shadow-black">
                  Start your journey for free!
                </button>
              </Link>
            </ul>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Section;

import React from "react";
import { Link } from "react-router-dom";
import photo2 from "../images/magePhotos/rangeAsymetry.png";
import "../css/footer.css";
import { motion } from "framer-motion";
const Section3 = () => {
  return (
    <div className="relative">
      {/* <hr /> */}
      <div className="custom-shape-divider-top-1676643218 ">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <section className="h-screen  w-full  bg-preflop_mage-purple pt-[10%] overflow-hidden">
        <div className="h-full w-full relative  items-end md:items-center grid  lg:grid-cols-2 lg:grid-flow-col p-5  max-w-screen mx-auto ">
          <div className=" justify-center items-center flex mb-5">
            <motion.div
              initial={{ x: window.innerWidth > 400 ? -350 : -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="sm:w-[550px] lg:w-[85ch] "
            >
              <img
                src={photo2}
                width="100%"
                loading="lazy"
                alt="showcase of one of the software functions"
                className="border-[#252525] lg:border-[12px] border-[8px] rounded-2xl shadow-xl shadow-black animate-float"
              ></img>
            </motion.div>
          </div>
          <div className="md:text-4xl text-2xl font-bold h-full flex   justify-center items-center text-white ">
            <motion.div
              initial={{ x: window.innerWidth > 400 ? 350 : 150, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="  flex  flex-col xl:text-4xl font-bold text-2xl  w-[85%]"
            >
              <ul className="list-disc">
                <li className="showDetails">
                  <p className="lg:mb-10 mb-5">
                    <span className="xl:text-[45px]  text-preflop_mage-gold ">
                      Get
                    </span>{" "}
                    to know your ranges.
                  </p>
                </li>
                <li>
                  <p className="lg:mb-10 mb-5">
                    <span className="xl:text-[45px]   text-preflop_mage-gold">
                      Find
                    </span>{" "}
                    out the strengths and weaknesses of your range.
                  </p>
                </li>
                <li>
                  <p className="lg:mb-10 mb-5">
                    <span className="xl:text-[45px]  text-preflop_mage-gold">
                      View
                    </span>{" "}
                    the range asymmetry in a single chart.
                  </p>
                </li>
                <Link to="/preflopMage">
                  <button className="bg-preflop_mage-gold p-4 lg:p-6 hover:bg-preflop_mage-gold_light text-base xl:text-3xl rounded-3xl  hover:text-black  hover:shadow-md hover:shadow-black">
                    One week for free !
                  </button>
                </Link>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section3;

import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-preflop_mage-purple h-max font-Roboto">
      <Navbar />
      <div className="h-[84vh] flex justify-center items-center flex-col text-center">
        <h1 className=" text-8xl text-white p-5">404</h1>
        <h1 className=" text-5xl text-white p-5">This page does not exists</h1>
        <div>
          <button className="btn-gold shadow" onClick={() => navigate("/")}>
            {" "}
            back to website
          </button>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default ErrorPage;

import logger from "redux-logger";

import storage from "redux-persist/lib/storage";
import user from "./userReducer";
import { persistReducer } from "redux-persist";

import { combineReducers } from "redux";

import persistStore from "redux-persist/es/persistStore";
import { applyMiddleware, legacy_createStore as createStore } from "redux";

const persistConfig = {
    key: "root",
    storage,
};

// const reducers = combineReducers({ user });

const persistedReducer = persistReducer(persistConfig, user);

const store = createStore(persistedReducer, applyMiddleware(logger));

const persistor = persistStore(store);

export default store;

export { persistor };

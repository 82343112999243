import React from "react";
import Navbar from "../components/Navbar";
import { SiDiscord, SiFacebook } from "react-icons/si";
import Footer from "../components/Footer";
const ContactPage = () => {
  return (
    <main className="h-screen w-full bg-preflop_mage-purple">
      <Navbar />
      <div className="w-full h-[90%] flex flex-col justify-center text-white font-Roboto font-bold items-center ">
        <h1 className="text-5xl text-preflop_mage-gold">Contact</h1>
        <div className="flex flex-col md:flex-row gap-10 m-10">
          <div className="flex max-w-xl ">
            <a
              href="https://www.facebook.com/profile.php?id=100090434471927"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-[#3874eb]  p-8 rounded-2xl hover:opacity-80 cursor-pointer">
                <SiFacebook className="w-24 h-24 text-white  p-2 rounded-2xl" />
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://discord.gg/Cmhst8xt7j"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-[#5865f2]  p-8 rounded-2xl hover:opacity-80 cursor-pointer">
                <SiDiscord className="w-24 h-24 text-white  p-2 rounded-2xl" />
              </div>
            </a>
          </div>
        </div>
        <h1 className="text-preflop_mage-gold">Owned By</h1>
        <ul>
          <li>Name : Vít Radosta</li>
          <li>Residency : 538 35, Zaječice 230</li>
          <li>IČO : 07167873</li>
          <li>Written down in Chrudim on 30/05/2018</li>
          <li>Email : preflopmageteam@preflopmage.com</li>
        </ul>
      </div>
      <Footer />
    </main>
  );
};

export default ContactPage;

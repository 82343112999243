import React from "react";
import Navbar from "../components/Navbar";
import logo from "../images/logo/LogoLightGradient.png";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN } from "../reducer/userReducer";
import { BsGoogle } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import "animate.css";
const LoginPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const provider2 = new FacebookAuthProvider();
  const register = (provider) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.

        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        dispatch({
          type: LOGIN,
          payload: { uid: user.uid, email: user.email },
        });
        if (state) {
          navigate("/activate", { state: { id: state } });
        } else {
          navigate("/pricing");
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  return (
    <div className="bg-preflop_mage-purple h-screen relative overflow-hidden">
      <Navbar />
      <div className="w-full absolute top-[35%] bg-preflop_mage-gold left-0 h-[350px] -skew-y-[12deg]  z-0 overflow-hidden" />
      <div className="h-[90vh] flex justify-center items-center z-10">
        <div className="bg-white flex flex-col items-center justify-center p-5 rounded-3xl w-[400px] z-10 animate__animated animate__fadeInDown ">
          <div className="m-3">
            <img src={logo} width={240} height={250} />
          </div>
          <div>
            <button
              variant="contained"
              className="btn m-2 min-w-[300px] align-baseline  hover:shadow-sm hover:shadow-black"
              onClick={() => register(provider)}
            >
              <span className="flex gap-[10px] justify-center items-center text-center">
                <span>
                  <BsGoogle />
                </span>
                Sign in with Google
              </span>
            </button>
          </div>
          <div>
            {" "}
            <button
              variant="contained"
              className="btn m-2 min-w-[300px]  hover:shadow-sm hover:shadow-black"
              onClick={() => register(provider2)}
            >
              <span className="flex gap-[10px] justify-center items-center text-center">
                <span>
                  <FaFacebook />
                </span>
                Sign in with Facebook
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

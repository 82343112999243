// Import the functions you need from the SDKs you need
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getStorage } from "firebase/storage";

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAxi4ZeSxcQ-eXlJcx9sJbSxSdknLWQLSM",
    authDomain: "preflop-mage.firebaseapp.com",
    projectId: "preflop-mage",
    storageBucket: "preflop-mage.appspot.com",
    messagingSenderId: "54394122968",
    appId: "1:54394122968:web:2ba7ac8c4600f84c065dad",
    measurementId: "G-DPCCVNHSRM",
};

// Initialize Firebase
const firebaseApp =
    getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
// const storage = getStorage(firebaseApp);

export { auth };
export default db;

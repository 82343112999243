import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Review from "../components/Review";
import Section from "../components/Section";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import "../css/mainPage.css";
import { FaArrowUp } from "react-icons/fa";
import SoftwareSection from "../components/SoftwareSection";
import ReviewsSection from "../components/ReviewsSection";
const MainPage = () => {
  const [arrow, setArrow] = useState("arrow-to-top arrow-01");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setArrow("arrow-to-top arrow-01 show");
        return;
      } else {
        setArrow("arrow-to-top arrow-01 ");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="bg-black min-h-full scroll-smooth font-Roboto overflow-hidden">
      <header>
        <title>Preflop Mage</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </header>

      <Header />
      <div className="h-[20vh] lg:h-[20vh] grid lg:grid-cols-3 gap-4  bg-gradient-to-t from-preflop_mage-purple to-black"></div>
      {/* <Review /> */}
      {/* <SoftwareSection /> */}
      {/* revie<w section */}
      {/* main */}
      <Section />
      <Section2 />
      <Section3 />
      <ReviewsSection />
      {/* {/* footer */}
      <div className="h-[150px] w-full bg-preflop_mage-purple"></div>
      <Footer />
      <div className={`${arrow}`}>
        <a href="#main">
          <FaArrowUp className="arrow" />
        </a>
      </div>
      {
        (window.onload = function () {
          (function (d, script) {
            script = d.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = "https://termify.io/script/63ef830da508f.js";
            d.getElementsByTagName("head")[0].appendChild(script);
          })(document);
        })
      }
    </div>
  );
};

export default MainPage;

import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
const SinglePrice = ({
  productId,
  productData,
  loadCheckout,
  isCurrentPackage,
  description,
  option,
}) => {
  return (
    <div
      className={
        productData?.name === "Elite"
          ? "rounded-3xl p-2  border border-sky-100 bg-sky-100/20 relative "
          : "p-2 relative "
      }
    >
      <div className={"flex flex-row w-64 m-5 "} key={productId}>
        <div>
          {productData?.name === "Elite" && (
            <div className="absolute text-white bg-preflop_mage-purple px-3 py-1 rounded-xl">
              Most popular
            </div>
          )}
          <h2 className="py-10 text-white text-3xl 2xl:text-4xl space-x-10  tracking-[3px]">
            {productData?.name}
          </h2>
          <div className="text-white  flex flex-row">
            <span className="text-4xl  font-bold">
              ${" "}
              {option > 0
                ? option > 1
                  ? (
                      (productData?.prices &&
                        productData?.prices[option]?.priceData.unit_amount /
                          100) / 12
                    ).toFixed(2)
                  : (
                      (productData?.prices &&
                        productData?.prices[option]?.priceData.unit_amount /
                          100) / 3
                    ).toFixed(2)
                : productData?.prices &&
                  productData?.prices[option]?.priceData.unit_amount / 100}
            </span>
            <div className="flex flex-col text-xs justify-center m-1 text-gray-300">
              <span>per</span>
              <span>month</span>
            </div>
            {option > 0 ? (
              <div className="flex  text-xs justify-center mt-5 text-gray-300">
                {productData?.prices &&
                  productData?.prices[option]?.priceData.unit_amount / 100}{" "}
                total
              </div>
            ) : (
              ""
            )}
          </div>
          <button
            className="btn-gold w-64 my-5 p-3  text-lg text-black hover:shadow-sm hover:shadow-black"
            onClick={() =>
              !isCurrentPackage &&
              loadCheckout(productData?.prices[option]?.priceId)
            }
          >
            {isCurrentPackage ? "Current Package" : "Subscribe"}
          </button>
          <div className="text-white ">
            <h5>This includes: </h5>

            <ul className="w-[15rem] ">
              <li className="">
                <p className="flex ">
                  <CheckCircleIcon className="min-w-[12px] max-w-[15px] mr-2  " />
                  {description?.desc1}
                </p>
              </li>
              <li>
                <p className="flex ">
                  <CheckCircleIcon className="min-w-[12px] max-w-[15px] mr-2 " />
                  {description?.desc2}
                </p>{" "}
              </li>
              <li>
                <p className="flex ">
                  <CheckCircleIcon className="min-w-[12px] max-w-[15px] mr-2 " />
                  {description?.desc3}
                </p>{" "}
              </li>
              {description?.desc4 && (
                <li>
                  <p className="flex ">
                    <CheckCircleIcon className="min-w-[12px] max-w-[15px] mr-2 " />
                    {description?.desc4}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePrice;

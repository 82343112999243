import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import video2 from "../images/magePhotos/trainerVideo2.mp4";
const Section2 = () => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1065) {
      setMobile(true);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1085) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (mobile) {
    return (
      <section className="h-screen bg-preflop_mage-dark_purple relative overflow-hidden">
        <div className="custom-shape-divider-top-1676647955 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="h-full w-full relative  items-end grid  lg:grid-cols-2 lg:grid-flow-col p-5  max-w-screen mx-auto">
          <div className=" justify-center items-end flex">
            <motion.div className="sm:w-[550px] lg:w-[900px] ">
              <video
                src={video2}
                width="100%"
                autoPlay
                alt="video showcase of a software"
                muted
                loop
                // loading="lazy"
                className="border-[#252525] lg:border-[12px] border-[8px] rounded-2xl shadow-lg shadow-black"
              >
                Sorry, your browser doesn't support videos.
              </video>
            </motion.div>
          </div>
          <div className="text-4xl font-bold h-full flex   justify-center items-center text-white ">
            <div className="  flex  flex-col  xl:text-4xl font-bold text-2xl  w-[85%]">
              <ul className="list-disc">
                <li className="showDetails">
                  <p className="lg:mb-10 mb-5">
                    <span className="xl:text-[55px]  text-preflop_mage-gold">
                      Improve
                    </span>{" "}
                    your preflop game in a fun way in our trainer
                  </p>
                </li>
                <li>
                  <p className="lg:mb-10 mb-5">
                    <span className="xl:text-[55px]   text-preflop_mage-gold">
                      You
                    </span>{" "}
                    can drill a specific spot or just play
                  </p>
                </li>
                <li>
                  <p className="lg:mb-10 mb-5">
                    <span className="xl:text-[55px]   text-preflop_mage-gold">
                      A great
                    </span>{" "}
                    warmup tool
                  </p>
                </li>
                <Link to="/preflopMage">
                  <button className="bg-preflop_mage-gold p-4 xl:p-6 hover:bg-preflop_mage-gold_light text-base lg:text-3xl rounded-3xl  hover:text-black  hover:shadow-md hover:shadow-black">
                    Try out trainer for free!
                  </button>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="h-screen bg-preflop_mage-dark_purple relative overflow-hidden">
        <div className="custom-shape-divider-top-1676647955 ">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="h-full w-full relative  items-center grid  lg:grid-cols-2 lg:grid-flow-col p-5  max-w-screen mx-auto">
          <div className="text-4xl font-bold h-full flex   justify-center items-center text-white overflow-hidden">
            <motion.div className="  flex  flex-col xl:text-4xl font-bold text-2xl  w-[85%]">
              <ul className="list-disc lg:pl-10">
                <li className="showDetails">
                  <p className="mb-10">
                    <span className="xl:text-[45px] text-3xl text-preflop_mage-gold">
                      Practice
                    </span>{" "}
                    your preflop game in our trainer and get instant feedback.
                  </p>
                </li>
                <li>
                  <p className="mb-10">
                    <span className="xl:text-[45px] text-3xl  text-preflop_mage-gold">
                      You
                    </span>{" "}
                    can fully configure the spots you practice.
                  </p>
                </li>
                <li>
                  <p className="mb-10">
                    <span className="xl:text-[45px] text-3xl  text-preflop_mage-gold">
                      The perfect
                    </span>{" "}
                    tool for warmups before session.
                  </p>
                </li>
                <Link to="/preflopMage">
                  <button className="bg-preflop_mage-gold p-4 xl:p-6 hover:bg-preflop_mage-gold_light text-base xl:text-3xl rounded-3xl  hover:text-black  hover:shadow-md hover:shadow-black">
                    Try out trainer for free!
                  </button>
                </Link>
              </ul>
            </motion.div>
          </div>
          <div className=" justify-center items-center flex">
            <motion.div className=" lg:w-[900px]  ">
              <video
                src={video2}
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                loading="lazy"
                className="border-[#252525] lg:border-[20px] border-[10px] rounded-2xl shadow-lg shadow-black"
              >
                Sorry, your browser doesn't support videos.
              </video>
            </motion.div>
          </div>
        </div>
      </section>
    );
  }
};

export default Section2;

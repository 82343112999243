import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LogoNavPurpleGradient from "../images/logo/LogoNavPurpleGradient.png";
import MailIcon from "@mui/icons-material/Mail";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function SwipeableTemporaryDrawer({ user, logout }) {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="flex items-center ml-10 my-5">
        <a href="/" className="">
          <img
            src={LogoNavPurpleGradient}
            className="w-[120px] md:w-[160px]"
            alt="logo"
          />
        </a>
      </div>
      <List>
        {[
          { text: "Pricing", to: "/pricing" },
          { text: "Preflop mage", to: "/preflopMage" },
          { text: "MTT", to: "" },
          { text: "FAQ", to: "/faq" },
          { text: "Contact", to: "/contact" },
          {
            text: "Subscription",
            to: `https://billing.stripe.com/p/login/8wMbLRew26kHa0E6oo?prefilled_email=${user?.email}`,
          },
        ].map((text, index) => {
          if (text.text === "Settings" && !user) {
            return;
          } else if (text.text === "MTT") {
            return (
              <div className="flex justify-center relative">
                <ListItem key={text.text} disablePadding>
                  <ListItemButton href={text.to}>
                    <ListItemText
                      className="m-10 line-through"
                      primary={text.text}
                    />
                    <div className="absolute text-xs left-[90px] top-4 -rotate-[28deg] font-bold">
                      Soon
                    </div>
                  </ListItemButton>
                </ListItem>
              </div>
            );
          } else {
            return (
              <div className="flex justify-center">
                <ListItem key={text.text} disablePadding>
                  <ListItemButton href={text.to}>
                    <ListItemText className="m-10" primary={text.text} />
                  </ListItemButton>
                </ListItem>
              </div>
            );
          }
        })}
      </List>
      <Divider />
      <div className="flex items-center ml-10 my-5">
        <button
          onClick={() => (user ? logout() : navigate("/login"))}
          className="btn "
        >
          {user ? "  Sign out" : "Sign in"}
        </button>
      </div>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <Bars3Icon className="w-10 text-preflop_mage-purple" />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

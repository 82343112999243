import React, { useEffect, useState } from "react";
import LogoNavPurpleGradient from "../images/logo/LogoNavPurpleGradient.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../reducer/userReducer";
import { Logout } from "../helper/userFunctions";
import { getAuth, signOut } from "firebase/auth";
import db, { auth } from "../firebase";
import { motion, useIsPresent } from "framer-motion";
import Slider from "./Slider";
import DropdownMenu from "./DropdownMenu";

const Navbar = () => {
  const [mobile, setMobile] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isSlider, setIsSlider] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isPresent = useIsPresent();
  const navigate = useNavigate();

  const Logout = () => {
    signOut(auth);
    dispatch({ type: LOGOUT });
    navigate("/");
  };
  useEffect(() => {
    if (window.innerWidth < 1065) {
      setMobile(true);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1085) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <nav className="w-auto ml-20 mr-20 h-16 bg-white rounded-b-[2rem] flex flex-row items-center">
        <div className="ml-5 w-[160px] md:w-[300px] flex justify-center cursor-pointer">
          <a href="/" className=" hover:translate-y-1 transition-all">
            <img
              src={LogoNavPurpleGradient}
              className="w-[120px] md:w-[160px]"
              alt="logo"
            />
          </a>
        </div>
        <div className="grow">
          {!mobile && (
            <ul className="flex flex-row items-center text-center  justify-center   uppercase tracking-[3px]">
              <li className="hover:translate-y-1 transition-all">
                <a
                  href="/pricing"
                  className="cursor-pointer hover:translate-y-1 transition-all"
                >
                  Pricing
                </a>
              </li>
              <li className="w-[300px] flex justify-center hover:translate-y-1 transition-all">
                <a href="/preflopMage" className="cursor-pointer ">
                  Preflop Mage
                </a>
              </li>
              <li className="relative hover:translate-y-1 transition-all">
                <a href="/faq" className="cursor-pointer ">
                  FAQ
                </a>
                {/* <div className="absolute text-xs left-4 top-4 -rotate-[20deg] font-bold">
                  Soon
                </div> */}
              </li>
            </ul>
          )}
        </div>
        <div className="mr-5 w-[160px] md:w-[300px] flex justify-center cursor-pointer ">
          {!mobile ? (
            user ? (
              <div className="relative ">
                <button
                  onClick={() => setScroll(!scroll)}
                  className="btn hover:shadow-sm hover:shadow-black "
                >
                  Settings
                </button>
                {scroll && (
                  <div>
                    <a
                      className="btn-gold absolute top-[55px] -left-3 hover:shadow-sm hover:shadow-black z-50"
                      href={`https://billing.stripe.com/p/login/8wMbLRew26kHa0E6oo?prefilled_email=${user?.email}`}
                    >
                      Subscription
                    </a>
                    <button
                      onClick={() => Logout()}
                      className="btn-gold  absolute top-[95px] left-1 hover:shadow-sm hover:shadow-black z-50"
                    >
                      Logout
                    </button>
                  </div>
                  // <DropdownMenu />
                )}
              </div>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="btn hover:shadow-sm hover:shadow-black  hover:translate-y-1 transition-all"
              >
                Sign in
              </button>
            )
          ) : (
            <Slider user={user} logout={Logout} />
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React from "react";
import DisclosureBox from "../components/DisclosureBox";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const FaqPage = () => {
  return (
    <div className=" h-max lg:w-[100%] bg-preflop_mage-purple ">
      <Navbar />
      <div className="flex justify-center text-white font-Roboto">
        <div className="flex  max-w-4xl text-center flex-col p-10 ">
          <h1 className="text-5xl p-5 font-bold">
            <span className="text-preflop_mage-gold">Frequently </span>
            asked questions
          </h1>
        </div>
      </div>
      <DisclosureBox />
      <Footer />
    </div>
  );
};

export default FaqPage;

import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import db, { auth } from "../firebase";
import Loader from "../components/Loader";
import SinglePrice from "../components/SinglePrice";

const description = [
  {
    desc1: "Manually constructed ranges for NL5 & NL50 stakes",
    desc2: "Ranges adjusted to pool tendencies",
    desc3: "Compact and easy to use charts",
    desc4: " 30 days free trial !",
  },
  {
    desc1: "5 complete preflop solutions from NL5 to NL200 stakes",
    desc2: "Any range can be copied and pasted to a solver within 1 click",
    desc3: "Cutting edge filtering to find any range within seconds",
    // desc4: "",
  },
  {
    desc1: "Additional preflop solution for NL500 stakes 200bb deep",
    desc2: "Advanced preflop trainer to practice the spots",
    desc3: "All Basic version functions",
    desc4: "Unique range asymmetry function",
  },
];
const PricingPage = () => {
  const [products, setProducts] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState(2);
  // const [updateSub, setUpdateSub] = useState("");

  const options = ["Monthly", "Every 3 Months", "Yearly"];
  let array = [];
  const user = useSelector((state) => state.user);
  // const UpgradeSub = async () => {
  //   let docs = getDocs(
  //     collection(db, `customers/${user.uid}/subscriptions`)
  //   ).then((querySnapshot) => {
  //     querySnapshot.forEach(async (subscription) => {
  //       console.log(subscription.data());
  //       setUpdateSub(subscription.data().items[0].subscription);
  //     });
  //   });

  // };
  // console.log("UPDATE", updateSub);
  const getSubscriptionsData = async () => {
    setIsLoading(true);
    let docs = getDocs(
      collection(db, `customers/${user.uid}/subscriptions`)
    ).then((querySnapshot) => {
      querySnapshot.forEach(async (subscription) => {
        setSubscription({
          role: subscription.data().role,
          current_period_end: subscription.data().current_period_end.seconds,
          current_period_start:
            subscription.data().current_period_start.seconds,
        });
      });
    });
    setIsLoading(false);
  };

  const getMePrices = async (productDoc) => {
    let pricesArray = [];
    let priceOfProducts = await getDocs(
      collection(db, `products/${productDoc.id}/prices`)
    );
    priceOfProducts.forEach((price) => {
      if (price?.data()?.active === true) {
        pricesArray.push({
          priceId: price.id,
          priceData: price.data(),
        });
      }
      array[productDoc.id].prices = pricesArray.sort(
        (a, b) => a.priceData.unit_amount - b.priceData.unit_amount
      );
    });

    return priceOfProducts;
  };

  const info2 = async () => {
    const q = query(collection(db, "products"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      array[doc.id] = doc.data();

      getMePrices(doc);
    });
    setProducts(array);
  };

  const loadCheckout = async (priceId) => {
    console.log("TADY");
    setIsLoading(true);
    const docRef = await addDoc(
      collection(db, `customers/${user.uid}/checkout_sessions`),
      {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        date: new Date(),
      }
    );
    onSnapshot(docRef, async (doc) => {
      const { error, sessionId } = doc.data();
      console.log("INFO", error, sessionId);
      if (error) {
        alert(`an error occurred ${error.message}`);
      }
      if (sessionId) {
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
        );
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };
  useEffect(() => {
    info2();
    getSubscriptionsData().then(() => setTimeout(() => setOption(0), 2000));
  }, []);
  return (
    <div className="bg-preflop_mage-purple h-max md:h-screen font-Roboto">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          {/* {subscription && (
                <p>
                    Renewal date :{" "}
                    {new Date(
                        subscription?.current_period_end * 1000
                    ).toLocaleDateString()}
                </p>
            )} */}
          <div className="flex flex-col items-center justify-center mt-5 2xl:mt-10">
            <div className="border rounded-2xl border-preflop_mage-gold sticky">
              {options.map((item, key) => {
                return (
                  <button
                    key={key}
                    className={
                      option === key
                        ? "btn-gold text-black 2xl:py-3"
                        : "btn hover:bg-preflop_mage-gold 2xl:py-3 "
                    }
                    onClick={() => setOption(key)}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            <div className="flex flex-col md:flex-row m-10">
              {products &&
                Object?.entries(products).map(
                  ([productId, productData], key) => {
                    const isCurrentPackage = productData?.name?.includes(
                      subscription?.role
                    );

                    if (productData.active === false) {
                      return;
                    }

                    let stupidData = description[key - 3];

                    return (
                      <SinglePrice
                        isCurrentPackage={isCurrentPackage}
                        option={option}
                        description={stupidData}
                        loadCheckout={loadCheckout}
                        productData={productData}
                        productId={productId}
                        key={key}
                      />
                    );
                  }
                )}
            </div>
          </div>
          {/* <button onClick={() => UpgradeSub()}>Click me</button> */}
        </>
      )}
    </div>
  );
};
const propsAreEqual = (prevProps, nextProps) => {
  const prevValue = JSON.stringify(prevProps.form?.getState());
  const nextValue = JSON.stringify(nextProps.form?.getState());
  return prevValue === nextValue;
};
export default React.memo(PricingPage, propsAreEqual);
